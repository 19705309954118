import React from 'react';
import { toPng } from 'html-to-image';
import useFlowchartStore from '../../Hooks/Zustand/flowchartStore';
import { shallow } from 'zustand/shallow';
import { Button } from '@chakra-ui/button';
import { FcDownload } from 'react-icons/fc';

const selector = (state) => ({
  title: state.title,
});

const imageWidth = 1024;
const imageHeight = 768;

function DownloadButtonComponent() {
  const { title } = useFlowchartStore(selector, shallow);

  const onClick = async () => {
    const dataUrl = await toPng(
      document.querySelector('.react-flow__renderer'),
      {
        style: {
          width: imageWidth,
          height: imageHeight,
        },
      }
    );

    downloadImage(dataUrl);
  };

  const downloadImage = (dataUrl) => {
    const a = document.createElement('a');

    a.setAttribute('download', `${title}.png`);
    a.setAttribute('href', dataUrl);
    a.click();
  };

  return (
    <>
      <Button bgColor={'transparent'} size={'sm'} onClick={onClick}>
        <FcDownload />
      </Button>
    </>
  );
}

export default DownloadButtonComponent;
