/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import {
  Avatar,
  Box,
  Button,
  Center,
  Fade,
  HStack,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { Handle, Position } from 'reactflow';

import useNodeClick from './useNodeClick';
import { useFlowchartStoreMindmap } from '../../../Hooks/Zustand/reactFlow';
import useUserStore from '../../../Hooks/Zustand/Store';
import { clientTypessense } from '../../../Api/Typesense';
import { getUserByUid } from '../../../Utils/getUser';

export default memo(({ id, data }) => {
  const selector = (state) => ({
    updateNodeLabel: state.updateNodeLabel,
    nodes: state.nodes,
    edges: state.edges,
  });
  const { updateNodeLabel } = useFlowchartStoreMindmap(selector);
  const [searchUsers, setSearchUsers]= useState([])

  const nodeClick = useNodeClick(id);

  const globalState = useUserStore();
  const [search, setSearch] = useState('')

  const toast = useToast()


  const handleSearchUsers = (q) => {
    setSearch(q)
    const companyUsers = globalState?.projects?.find(
      (x) => x.id === globalState?.currentProject
    );
    const userChunks = chunkArray(companyUsers?.users, 100);
    
    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };
    
      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });
    
    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchUsers(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };
    
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleClick = (label) => {
    if (label === 1) {
      setSearch('')
      nodeClick();
    }

    setSelectedNode(null);
    setSearch('')
  };

  const [selectedNode, setSelectedNode] = useState(null);
  const textareaRef = useRef(null);

  const handleNodeClick = () => {
    if (selectedNode === data) {
      setSelectedNode(null);
    } else {
      setSelectedNode(data);
      // save()
    }
  };

  const handleChange = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }

    // save()
  }, []);

  useEffect(() => {
    handleChange();
  }, []);

  const handleSelect = (data) => {
    onChange(data)
    setSearchUsers([])
    setSearch('')
  }

  const onChange = useCallback(
    async (x) => {
      updateNodeLabel(id, x.document.id);
    },

    [id]
  );

  return (
    <>
      <Box>

        <HStack>
          

          <Fade in={true} onClick={handleNodeClick} initialScale={3}>
            <HStack
              display="flex"
              shadow="base"
              p="2"
              m="1"
              bgColor="white"
              minW="2xs"
              w={`${data.label.length * 10}px`}
              maxW={'md'}
            >
              <Box
                height="100%"
                margin-right="4px"
                align-items="center"
                justifyItems="center"
                pointer-events="all"
              >
                <Avatar size={'sm'} name={getUserByUid(globalState.users, data.label)?.email} src={getUserByUid(globalState.users, data.label)?.image || getUserByUid(globalState.users, data.label)?.email}/>
              </Box>
              <Textarea
                fontSize="md"
                type="text"
                value={search ? search : (getUserByUid(globalState.users, data.label)?.name || data?.label)}
                onChange={(e) => handleSearchUsers(e.target.value)}
                minW="fit-content"
                border="none"
                ref={textareaRef}
                rows={1}
                style={{ resize: 'none', overflowY: 'hidden', height: 'auto' }}
              />    
              <>
                <Handle
                  style={{
                    width: '11px',
                    height: '11px',
                    backgroundColor: 'transparent',
                  }}
                  type="source"
                  position={Position.Top}
                  id="a"
                />
                <Handle
                  style={{
                    width: '11px',
                    height: '11px',
                    backgroundColor: 'transparent',
                  }}
                  type="source"
                  position={Position.Right}
                  id="b"
                />
                <Handle
                  style={{
                    width: '11px',
                    height: '11px',
                    backgroundColor: 'transparent',
                  }}
                  type="source"
                  position={Position.Bottom}
                  id="c"
                />
                <Handle
                  style={{
                    width: '11px',
                    height: '11px',
                    backgroundColor: 'transparent',
                  }}
                  type="source"
                  position={Position.Left}
                  id="d"
                />
              </>

              
            </HStack>
          </Fade>

        

        </HStack>

        <Center style={{ position: 'absolute', bottom: '-30px', left: '45%' }}>
          <Button
            variant={'outline'}
            colorScheme="blue"
            onClick={() => handleClick(1)}
            size={'xs'}
            fontSize={'8'}
            border={'transparent'}
            display={selectedNode === data ? 'block' : 'none'}
          >
            <FaPlusCircle size={15} />
          </Button>
        </Center>

        <Stack
          maxH={300}
          overflowY={'auto'}
          position={'absolute'}
          bgColor={'white'}
        >
          {searchUsers?.length > 0 && search !== '' ? (
            searchUsers?.map((x, index) => {
              return (
                <HStack key={index} p="2" borderBottom="1px">
                  <Avatar
                    name={x.document.name}
                    src={x.document.image ? x.document.image : ''}
                  />
                  <Box>
                    <Text>{x.document.name}</Text>
                    <Text>{x.document.email}</Text>
                  </Box>
                  <Spacer />
                  <Button
                    colorScheme="green"
                    onClick={() => handleSelect(x)}
                  >
                          +
                  </Button>
                </HStack>
              );
            })
          ) : (
            <></>
          )}
        </Stack>
      </Box>
    </>
  );
});
