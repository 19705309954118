import {
  average,
  collection,
  getAggregateFromServer,
  getCountFromServer,
  query,
  sum,
  where,
} from 'firebase/firestore';
import { db } from '../Config/firebase';

//COUNT
export const getCountKpi = async (kpiId) => {
  try {
    const coll = collection(db, 'kpi_data');
    const q = query(coll, where('kpiId', '==', kpiId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  } catch (err) {
    return 0;
  }
};

//SUM
export const getSumKpi = async (kpiId) => {
  try {
    const coll = collection(db, 'kpi_data');
    const q = query(coll, where('kpiId', '==', kpiId));
    const snapshot = await getAggregateFromServer(q, {
      totalPopulation: sum('actual'),
    });
    return snapshot.data().totalPopulation;
  } catch (err) {
    return 0;
  }
};

//AVG
export const getAvgKpi = async (kpiId) => {
  try {
    const coll = collection(db, 'kpi_data');
    const q = query(coll, where('kpiId', '==', kpiId));
    const snapshot = await getAggregateFromServer(q, {
      averagePopulation: average('actual'),
    });
    return snapshot.data().averagePopulation;
  } catch (err) {
    return 0;
  }
};

export const calculateStats = async (dataKpi) => {
  const results = await Promise.all(
    dataKpi.map(async (kpiId) => {
      const count = await getCountKpi(kpiId);
      const sum = await getSumKpi(kpiId);
      const average = await getAvgKpi(kpiId);
      return { count, sum, average };
    })
  );

  const counts = results.map((result) => result.count);
  const sums = results.map((result) => result.sum);
  const averages = results.map((result) => result.average);

  return { counts, sums, averages };
};

export const processDataWithCounts = (x, counts, sums, averages) => {
  return x.map((item, index) => {
    let statsCounter = counts[index];
    let statsTotalActual = sums[index];
    let statsAverage = averages[index];
    let statsAverageTarget = (item.target * statsCounter) / statsCounter;
    let statsTotalTarget = item.target * statsCounter;

    statsCounter = statsCounter || 0;
    statsTotalActual = statsTotalActual || 0;
    statsAverage = statsAverage || 0;
    statsAverageTarget = statsAverageTarget || 0;
    statsTotalTarget = statsTotalTarget || 0;

    let percent = 0;
    if (item.achivement === 'high') {
      percent = (statsTotalActual / statsTotalTarget) * 100;
    } else {
      if (statsTotalActual >= statsTotalTarget) {
        percent =
          (-((statsTotalActual - statsTotalTarget) / statsTotalTarget) * 100) + 100;
      } else {
        percent =
          (((statsTotalTarget - statsTotalActual) / statsTotalTarget) * 100) + 100;
      }
    }

    let statsProgress = parseInt(item.target === '0' ? 100 : (percent));
    statsProgress = isNaN(statsProgress) ? 0 : statsProgress;

    return {
      ...item,
      statsCounter,
      statsTotalActual,
      statsAverage,
      statsTotalTarget,
      statsAverageTarget,
      statsProgress,
    };
  });
};
