/* eslint-disable react/prop-types */
import React from 'react';

const SopPrintPdf = ({ data, company }) => {
  return (
    <div
      style={{
        padding: '20px',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: '50px' }}>
        <h3
          style={{
            textTransform: 'uppercase',
            marginBottom:
              '5px',
            textAlign: 'center',
            fontSize: '24px',
            color: 'black',
          }}
        >
          {data?.title}
        </h3>
        <p
          style={{ color: 'black', textTransform: 'capitalize', marginTop: '0' }}
        >
          company: {company}
        </p>
      </div>

      <div style={{ textAlign: 'justify' }}>
        {data?.description && (
          <p style={{ color: 'black' }}>
            <span style={{ fontWeight: 'bold' }}>Description</span>:{' '}
            {data?.description}
          </p>
        )}
        <div
          style={{ color: 'black' }}
          dangerouslySetInnerHTML={{
            __html: data?.dataHtml
              ? data.dataHtml.replace(
                /<li class="ql-indent-\d">([^<]*?)<\/li>/g,
                '<ul style="list-style-type: disc;"><li>$1</li></ul>'
              )
              : '',
          }}
        />
      </div>
    </div>
  );
};

export default SopPrintPdf;
