/* eslint-disable react/prop-types */
import { Button, Center, Divider, HStack, Text } from '@chakra-ui/react';
import React from 'react'
import { FcRedo, FcShare, FcUndo } from 'react-icons/fc';
import { Panel } from 'reactflow';
import DownloadButtonComponent from '../Buttons/DownloadButtonComponent';

function PanelControl({ data, globalState, canUndo, undo, canRedo, redo, setModalProjectUser, save }) {
  if (data?.owner?.includes(globalState?.uid)) {
    return (
      <Panel position="top-right">
        <HStack
          bgColor={'white'}
          p={2}
          px={7}
          borderRadius={'3xl'}
          boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        >
          <Text color={'lightgrey'} size={'sm'}>
                        Control Panel
          </Text>
          <Center height="30px">
            <Divider orientation="vertical" />
          </Center>
          <DownloadButtonComponent />
          <Button
            bgColor={'transparent'}
            size={'sm'}
            disabled={canUndo}
            onClick={undo}
          >
            <FcUndo />
          </Button>
          <Button
            bgColor={'transparent'}
            size={'sm'}
            disabled={canRedo}
            onClick={redo}
          >
            <FcRedo />
          </Button>
          <Button
            bgColor={'transparent'}
            size={'sm'}
            onClick={() => setModalProjectUser(true)}
          >
            <FcShare />
          </Button>
          <Button
            bgColor={'blue.600'}

            size={'sm'} onClick={save}>
                        Save
          </Button>
        </HStack>
      </Panel>
    );
  } else {
    return (
      <Panel position="top-right">
        <HStack
          p={2}
          px={7}
          borderRadius={'3xl'}
          boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        >
          <Text color={'lightgrey'} size={'sm'}>
                        Control Panel
          </Text>
          <Center height="30px">
            <Divider orientation="vertical" />
          </Center>
          <DownloadButtonComponent />
        </HStack>
      </Panel>
    );
  }
}

export default PanelControl