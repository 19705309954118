/* eslint-disable no-inner-declarations */
/* eslint-disable indent */
import {
  Box,
  HStack,
  Heading,
  Input,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Progress,
  Text,
  Avatar,
  Badge,
  Icon,
  AvatarGroup,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tooltip,
  Select,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Stack,
  useToast,
  Tag,
  Button,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import { useNavigate } from 'react-router-dom';
import {
  FcDisplay,
  FcFile,
  FcNegativeDynamic,
  FcPositiveDynamic,
} from 'react-icons/fc';
import KpiNewPage from './Component/KpiNewComponent';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';
import moment from 'moment';
import useUserStore from '../../Hooks/Zustand/Store';
import { deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { calculateStats, processDataWithCounts } from '../../Utils/kpiCalc';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getUserByUid } from '../../Utils/getUser';
import { formatNumberKpi } from '../../Utils/numberUtil';
import { colorSchemeNumberUtil } from '../../Utils/colorUtil';
import { formatFrice } from '../../Utils/Helper';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import SopViewTemplate from '../Sop/Component/SopViewTemplate';
import KpiEditComponent from './Component/KpiEditComponent';
import { TypesenseRestApi } from '../../Api/Typesense';


function KpiListPage() {
  const navigate = useNavigate();
  const globalState = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [sort, setSort] = useState({ name: 'statsProgress', sort: true });
  const [slider, setSlider] = useState([0, 100]);
  const [sliderData, setSliderData] = useState([0, 100]);
  const [filterOwner, setFilterOwner] = useState(false);
  const [dataSop, setDataSop] = useState([]);
  const [sopModalView, setSopModalView] = useState(false);
  const [sopActive, setSopActive] = useState({});

  const [filterData, setFilterData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({});

  const [alertDelete, setAlertDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('');

  const [modalEdit, setModalEdit] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ field: 'statsProgress', direction: 'desc' })
  const itemsPerPage = 8;

  const handleDeleteFile = async () => {
    setLoading(true);

    if (data.createdBy !== globalState.uid) {
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: 'Alert!',
          description: ('Im sorry, but based on your role,  You dont have access.'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    try {
      await deleteDocumentFirebase('kpi', active?.id);

      toast({
        title: 'Success',
        description: 'kpi file deleted',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: ('Error!'),
        description: 'Failed to delete kpi file' + error,
        duration: 3000,
        status: 'error',
      });
    } finally {
      setLoading(false);
      setAlertDelete(false);
      setActive('');
      navigate(-1);
    }
  };

  const handleAlertDelete = (x) => {
    setActive(x);
    setAlertDelete(true);
  };

  const toast = useToast();

  const getData = async () => {
    globalState.setIsLoading(true);
    const startIndex = (currentPage - 1) * itemsPerPage;
    let conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      {
        field: 'users',
        operator: 'array-contains',
        value: globalState.uid,
      },
    ];
    const limitValue = startIndex + itemsPerPage;

    if (filterOwner === true) {
      conditions = [
        {
          field: 'companyId',
          operator: '==',
          value: globalState.currentCompany,
        },
        {
          field: 'projectId',
          operator: '==',
          value: globalState.currentProject,
        },
      ];
    }

    try {
      const x = await getCollectionFirebase(
        'kpi',
        conditions,
        sortBy,
        limitValue
      );


      const dataKpi = x.map((item) => item.kpiId);

      const { counts, sums, averages } = await calculateStats(dataKpi);

      const dataWithCounts = processDataWithCounts(x, counts, sums, averages);

      setData(dataWithCounts);
      setFilterData(dataWithCounts);

      setTotalPages(Math.ceil(dataWithCounts.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleFilter = (newFilter) => {
    const combinedFilter = { ...currentFilter, ...newFilter };
    setCurrentFilter(combinedFilter);

    let filteredData = [...data];

    if (combinedFilter.slider) {
      filteredData = filteredData.filter(
        (item) =>
          item.statsProgress >= combinedFilter.slider[0] &&
          item.statsProgress <=
            (combinedFilter.slider[1] === 100
              ? Infinity
              : combinedFilter.slider[1])
      );
    }

    if (combinedFilter.period) {
      filteredData = filteredData.filter(
        (item) => item.period === combinedFilter.period
      );
    }

    if (combinedFilter.title) {
      filteredData = filteredData.filter((item) =>
        item.title.toLowerCase().includes(combinedFilter.title.toLowerCase())
      );
    }

    if (combinedFilter.sort) {
      filteredData.sort((a, b) => {
        if (combinedFilter.sort.name === 'statsProgress') {
          return combinedFilter.sort.sort
            ? b.statsProgress - a.statsProgress
            : a.statsProgress - b.statsProgress;
        }
        return 0;
      });
    }

    setFilterData(filteredData);
  };

  const handleTypesenseSearch = async (q) => {
    if (q === '') {
      getData();
      return;
    }

    const searchParameters = {
      q: q,
      query_by: 'title',
      //Tolong dinyalakan jika sudah ada data
      filter_by: `companyId:${globalState?.currentCompany} && projectId:${globalState?.currentProject}`,
      sort_by: '_text_match:desc',
    };
    try {
      const response = await TypesenseRestApi({
        collection: 'kpi',
        ...searchParameters,
      });
      const newData = [];
      await Promise.all(
        response?.hits?.map(async (hit) => {
          const itemId = hit?.document?.id;
          if (itemId) {
            const data = await getSingleDocumentFirebase('kpi', itemId);
            newData.push({ ...data, id: itemId });
          }
        })
      );

      const dataKpi = newData.map((item) => item.kpiId);

      const { counts, sums, averages } = await calculateStats(dataKpi);

      const dataWithCounts = processDataWithCounts(newData, counts, sums, averages);

      setData(dataWithCounts);
      setFilterData(dataWithCounts);

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const handleOpenAdd = () => {
    if (data.createdBy !== globalState.uid) {
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: 'Alert!',
          description: ('Im sorry, but based on your role,  You dont have access.'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    onOpen();
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSeeAll = () => {
    if (globalState.roleCompany !== 'owner') {
      toast({
        title: ('Alert!'),
        description: ('Im sorry, but based on your role,  You dont have access.'),
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setFilterOwner((prevFilterOwner) => !prevFilterOwner);
  };

  useEffect(() => {
    getData();
  }, [globalState?.currentProject, currentPage, filterOwner]);

  const getDataSop = async () => {
    globalState.setIsLoading(true);
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 50;
    try {
      const x = await getCollectionFirebase(
        'sop',
        conditions,
        sortBy,
        limitValue
      );
      setDataSop(x);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getDataSop();

    return () => {};
  }, []);

  const handleSopOpen = (data) => {
    setSopActive(data);
    setSopModalView(true);
  };

  const handleSopClose = () => {
    setSopModalView(false);
    setSopActive({});
  };

  const handleEditOpen = (data) => {
    setModalEdit(true);
    setActive(data);
  };

  const handleEditClose = () => {
    setActive('');
    setModalEdit(false);
  };

  const handleSortDate = () => {
    if (sortBy.direction === 'desc') {
      setSortBy({field : 'createdAt', direction : 'asc'})
      getData()
    } else {
      setSortBy({field : 'createdAt', direction : 'desc'})
      getData()
    }
  }

  return (
    <Stack p={[1, 1, 5]}>
      <HStack>
        <BackButtons />
        <Box>
          <Heading size={'md'}>KPI</Heading>
        </Box>
        <Spacer />
      </HStack>

      <Stack shadow="base" p={5} spacing={4}>
        <Stack flexDirection={['column', 'row', 'row']}>
          <HStack>
            <Stack>
              <Select
                onChange={(e) => {
                  handleFilter({ period: e.target.value });
                }}
              >
                <option value="">All</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Select>
            </Stack>

            {/* <Stack>
              <Input type='text' placeholder='OKR' />
            </Stack> */}
          </HStack>
          <Spacer />

          <Stack>
            <Input
              type="text"
              placeholder={('Search Text')}
              onChange={(e) => handleTypesenseSearch(e.target.value)}
            />
          </Stack>

          <HStack>
            <DynamicButton
              action={'create'}
              title={('Add Kpi')}
              variant={'solid'}
              onClick={handleOpenAdd}
            />
            <DynamicButton
              action={'read'}
              title={('See All')}
              variant={'solid'}
              onClick={handleSeeAll}
            />
          </HStack>
        </Stack>

        <HStack spacing={0}>
          <Text fontSize="sm" fontWeight={500}>
            {('Search By Progress')}
          </Text>
          <RangeSlider
            defaultValue={slider}
            min={-100}
            max={100}
            step={5}
            onChange={(val) => {
              setSliderData(val);
              handleFilter({ slider: val });
            }}
            onChangeEnd={(val) => {
              setSlider(val);
              handleFilter({ slider: val });
            }}
          >
            <RangeSliderTrack bg="red.100" mr="2">
              <RangeSliderFilledTrack bg="tomato" />
            </RangeSliderTrack>
            <RangeSliderThumb displayName="kodok" boxSize={8} index={0}>
              <Text fontSize="2xs">{sliderData[0]}%</Text>
            </RangeSliderThumb>
            <RangeSliderThumb boxSize={8} index={1}>
              <Tooltip label={sliderData[1] === 100 ? '100%+' : sliderData[1]}>
                <Text fontSize="2xs">
                  {sliderData[1] === 100 ? '100%+' : sliderData[1]}
                </Text>
              </Tooltip>
            </RangeSliderThumb>
          </RangeSlider>
        </HStack>
      </Stack>

      {filterData.length > 0 ? (
        <TableContainer>
          <Table overflowX="auto">
            <Thead>
              <Tr>
                <Th textAlign="center">KPI</Th>
                <Th
                  textAlign="center"
                  onClick={() => {
                    setSort({ name: 'statsProgress', sort: !sort.sort });
                    handleFilter({
                      sort: { name: 'statsProgress', sort: !sort.sort },
                    });
                  }}
                >
                  {('Status')} & {('Progress')}{' '}
                  {sort.name === 'statsProgress' ? (
                    sort.sort ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                  ) : (
                    <></>
                  )}
                </Th>

                <Th>OKR</Th>
                <Th>{('Pic')}</Th>
                <Th>{('Assignee')}</Th>
                <Th>{('Period')}</Th>
                <Th>{('File')}</Th>
                <Th
                  onClick={handleSortDate}
                >
                {('Updated')}
                {
                    sortBy.direction === 'desc' ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )
                }
                </Th>
                <Th>{('Action')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filterData?.map((x, i) => {
                return (
                  <Tr key={i}>
                    <Td>
                      <HStack
                        spacing={1}
                        alignItems={'flex-start'}
                        justifyContent={'flex-start'}
                      >
                        <Stack>
                          <Icon as={FcDisplay} boxSize={10} />
                        </Stack>
                        <Stack>
                          <Tooltip label={x.title} aria-label="A tooltip">
                            <Stack spacing={1}>
                              <Text
                                cursor="pointer"
                                fontWeight={500}
                                noOfLines={1}
                                onClick={() => navigate(`/kpi/list/${x.id}`)}
                              >
                                {x.title}
                              </Text>
                              <Text fontWeight={500} fontSize="xs">
                                {('Assignor')}:{' '}
                                {getUserByUid(globalState?.users, x?.createdBy)
                                  ?.email || ''}
                              </Text>
                            </Stack>
                          </Tooltip>
                          <HStack overflowX={'auto'} w={'200px'}>
                            {x?.tags?.length > 0 &&
                              x?.tags?.map((y, index) => {
                                return (
                                  <Stack key={index}>
                                    <Tag size={'sm'} colorScheme="green">
                                      <Text fontSize={'xs'}>{y}</Text>
                                    </Tag>
                                  </Stack>
                                );
                              })}
                          </HStack>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>
                      <HStack>
                        <Box>
                          <Text textAlign="right" fontSize="2xs">
                            {x?.isAvarage
                              ? `${('Average')}: ${formatNumberKpi(
                                  x.statsAverage
                                )}`
                              : `Total: ${formatNumberKpi(x.statsTotalActual)}`}
                          </Text>
                          <Progress
                            borderRadius="md"
                            value={x.statsProgress}
                            colorScheme={colorSchemeNumberUtil(x.statsProgress)}
                            minW="36"
                          />
                          <Text textAlign="right" fontSize="2xs">
                            Target:{' '}
                            {x.isAvarage
                              ? formatFrice(x.target)
                              : formatFrice(x.statsTotalTarget)}
                          </Text>
                        </Box>
                        {x?.achivement === 'high' ? (
                          <HStack>
                            <Icon as={FcPositiveDynamic} boxSize={10} />
                          </HStack>
                        ) : (
                          <HStack>
                            <Icon as={FcNegativeDynamic} boxSize={10} />
                          </HStack>
                        )}
                        <Badge
                          borderRadius="md"
                          w="full"
                          textAlign="center"
                          fontSize="xl"
                          p="2"
                          colorScheme={colorSchemeNumberUtil(x.statsProgress)}
                        >
                          {x.statsProgress}%
                        </Badge>
                      </HStack>
                    </Td>
                    <Td>
                      {x?.objective ? (
                        x.objective.map((y, i) => (
                          <Badge key={i} colorScheme="green">
                            {y}
                          </Badge>
                        ))
                      ) : (
                        <Badge colorScheme="red">{('No OKR')}</Badge>
                      )}
                    </Td>
                    <Td>
                      {x?.pic?.length > 0 && (
                        <AvatarGroup cursor="pointer" size="sm" max={4} gap={1}>
                          {x?.pic.map((user, i) => {
                            return (
                              <Avatar
                                key={i}
                                size={'sm'}
                                name={user?.name}
                                src={user?.image || user?.name}
                              />
                            );
                          })}
                        </AvatarGroup>
                      )}
                    </Td>
                    <Td>
                      {x?.usersDisplay?.length > 0 && (
                        <AvatarGroup cursor="pointer" size="sm" max={4}>
                          {x?.usersDisplay.map((user, i) => {
                            return (
                              <Avatar
                                key={i}
                                size={'sm'}
                                name={user?.name}
                                src={user?.image || user?.name}
                              />
                            );
                          })}
                        </AvatarGroup>
                      )}
                    </Td>
                    <Td>
                      <Badge
                        w={'full'}
                        textAlign="center"
                        colorScheme={
                          x?.period === 'daily'
                            ? 'green'
                            : x?.period === 'weekly'
                            ? 'yellow'
                            : 'blue'
                        }
                      >
                        {x?.period?.toUpperCase()}{' '}
                      </Badge>
                    </Td>
                    <Td>
                      {x?.sopId?.length > 0 && (
                        <HStack spacing={-1}>
                          {x?.sopId?.map((y, i) => {
                            const findSop = dataSop?.find((x) => x.id === y);

                            return (
                              <Tooltip
                                key={i}
                                label={findSop?.title}
                                aria-label="Name"
                              >
                                <Box cursor={'pointer'}>
                                  <FcFile
                                    size={25}
                                    onClick={() => handleSopOpen(findSop)}
                                  />
                                </Box>
                              </Tooltip>
                            );
                          })}
                        </HStack>
                      )}
                    </Td>

                    <Td>
                      <Stack>
                        <Text fontSize="xs" fontWeight={500}>
                          {moment
                            .unix(x.lastUpdated.seconds || x.lastUpdated)
                            .fromNow()}
                        </Text>
                      </Stack>
                    </Td>

                    <Td>
                      <HStack>
                        <DynamicButton
                          action={'update'}
                          variant={'solid'}
                          onClick={() => handleEditOpen(x)}
                        />
                        <DynamicButton
                          action={'delete'}
                          variant={'solid'}
                          onClick={() => handleAlertDelete(x)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <AlertArrayNull titleData={'KPI'} action={('Add Kpi')} />
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {currentPage <= totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              {('Load More')}
            </Button>
          )}
        </Box>
      </Stack>

      <AlertDialogV2
        isLoading={loading}
        isOpen={alertDelete}
        onClose={() => setAlertDelete(false)}
        title={`delete Kpi ${active?.title }`}
        onAction={handleDeleteFile}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{('Add Kpi')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <KpiNewPage dataSop={dataSop} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={sopModalView} onClose={handleSopClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{sopActive?.title || 'Untitled'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SopViewTemplate data={sopActive} onClose={handleSopClose} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalEdit} onClose={handleEditClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{('Edit')} KPI</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <KpiEditComponent
              dataSop={dataSop}
              dataKpi={active}
              onClose={handleEditClose}
              globalState={globalState}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default KpiListPage;
