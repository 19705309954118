/* eslint-disable no-useless-catch */
import _axios from './AxiosBarrier';


export const addActivity = async (data,) => {
  try {
    const res = await _axios.post('/kanban-addActivity', data,);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addSubscribeTopic = async (data,) => {
  try {
    const res = await _axios.post('/notification-subscribeTopic', data,);
    return res;
  } catch (error) {
    throw error?.response?.data || error
  }
};

export const addPostTopic = async (data,) => {
  try {
    const res = await _axios.post('/notification-postTopic', data,);
    return res;
  } catch (error) {
    throw error?.response?.data || error
  }
};

// Tambahkan fungsi-fungsi lain untuk API activity lainnya jika diperlukan
