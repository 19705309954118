import { create } from 'zustand';
import {
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';

const useFlowchartStore = create((set, get) => ({
  nodes: [],
  edges: [],
  title: '',
  owner: false,

  onNodesChange: (changes) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection) => {
    const { source, target, sourceHandle, targetHandle } = connection;
    if (source && target) {
      set((state) => ({
        edges: [
          ...state.edges,
          {
            id: state.edges.some(
              (edge) => edge.id === `reactflow__edge-${source}-${target}`
            )
              ? `reactflow__edge-${source}-${target}-${sourceHandle}`
              : `reactflow__edge-${source}-${target}`,
            source,
            target,
            sourceHandle,
            targetHandle,
            // You can add other properties like label, style, etc. here
          },
        ],
      }));
    }
  },
  updateNodeLabel: (id, label) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === id) {
          // it's important to create a new object here, to inform React Flow about the changes
          node.data = { ...node.data, label: label };
        }

        return node;
      }),
    });
  },
  setNodes: (data) => {
    set({
      nodes: data,
    });
  },
  setEdges: (data) => {
    set({
      edges: data,
    });
  },
  setTitle: (title) => {
    set({
      title: title,
    });
  },
  setOwner: (owner) => {
    set({
      owner: owner,
    });
  },
}));

export default useFlowchartStore;
