/* eslint-disable react/prop-types */
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import './datePicker.css';
import React, { useState } from 'react';
import { DateRange, DefinedRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
const DatePickerNew = ({ onDateChange }) => {
  const isTablet = useBreakpointValue({ base: false, sm: true });
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  const handleDateRangeChange = (item) => {
    setDate([item.selection]);
    onDateChange(item.selection); // Pass the date range to the parent component
  };

  return (
    <>
      <Box
        overflow={'auto'}
        sx={{
          '&::-webkit-scrollbar': {
            w: '2',
            h: '2',
          },
          '&::-webkit-scrollbar-track': {
            w: '6',
            h: '5',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10',
            bg: 'gray.200',
          },
        }}
      >
        <Flex w={'full'}>
          {isTablet && <DefinedRange onChange={handleDateRangeChange} ranges={date} />}
          
          <DateRange
            maxDate={addDays(new Date(), 0)}
            editableDateInputs={true}
            onChange={handleDateRangeChange}
            moveRangeOnFirstSelection={false}
            ranges={date}
          />
        </Flex>
      </Box>
    </>
  );
};

export default DatePickerNew;
